<template>
    <el-dialog title="字典数据" :visible.sync="dialogTableVisible" :close-on-click-modal="false" >
        <el-button type="primary" icon="el-icon-plus" style="margin-bottom: 20px;" @click="dictDataAddBtn(false)">新增</el-button>

        <list-item ref="listitem" @onRefreshList="onLoadList"></list-item>

        <el-table  border :data="gridData" style="width: 100%">
            <el-table-column 
                type="index"
                label="字典编码"
                align="center"
                width="100">
            </el-table-column>
            <el-table-column 
                prop="label"
                label="字典标签"
                align="center"
                width="100">
            </el-table-column>
            <el-table-column 
                prop="state"
                label="状态"
                align="center"
                width="80">
                    <template slot-scope="scope">
                        <el-tag type="primary" plain  v-if="scope.row.state == 0">正常</el-tag>
                        <el-tag type="info" plain v-if="scope.row.state == 1">停用</el-tag>
                    </template>
            </el-table-column>
            <el-table-column 
                prop="remark"
                label="备注"
                align="center"
                width="120">
            </el-table-column>
             <el-table-column 
                prop="sort"
                label="排序"
                align="center"
                width="50">
            </el-table-column>
              <el-table-column
                label="创建时间"
                align="center"
                width="150">
                    <template slot-scope="scope">
                        {{scope.row.createDate | date}}
                    </template>
            </el-table-column>
            <el-table-column
                label="操作"
                align="center">
                    <template slot-scope="scope">
                        <el-button  type="text" size="small" icon="el-icon-edit" @click="dictDataAddBtn(true, scope.row)">编辑</el-button>
                        <el-button type="text" size="small" icon="el-icon-delete" @click="dictDataRemoveBtn(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
        </el-table>
    </el-dialog>
</template>
<script>
import ListItem from './ListItem.vue'
import { logsmanagerAPI } from 'liankong-ui-api'
export default {
    data(){
        return {
            dialogTableVisible: false,
            gridData: [],
            dictDataInfo: {}
        }
    },
    components: {
        ListItem
    },
    methods: {
        init(dictDataInfo) {
            this.dictDataInfo = dictDataInfo;
            this.onLoadList();
        },
        // 新增、编辑
        dictDataAddBtn(flags, newRowInfo) {
            this.$refs.listitem.innerVisible = true;
            
            if(flags){
                this.$nextTick(()=>{
                    this.$refs.listitem.innerInit(flags,newRowInfo);
                })
            } else { 
                this.$nextTick(()=>{
                    this.$refs.listitem.innerInit(flags,this.dictDataInfo);
                })
            }
           
        },
        onLoadList(){
            logsmanagerAPI.dictList(this.dictDataInfo.id).then(res=>{
                if(res.code == '0') {
                    this.gridData = res.data;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 删除
        dictDataRemoveBtn(row){
            this.$confirm('是否确认删除名称为"' + row.label + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                logsmanagerAPI.deleteListEdits(row.id).then(res=>{
                    if(res.code =='0') {
                        this.$message.success('删除成功');
                        this.onLoadList();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(error=>{
                    this.$message.error(error.message);
                })
            })
        }
    }
}
</script>