<template>
     <el-dialog
      :title="innerFlags ? '数据编辑' : '数据添加' "
      :visible.sync="innerVisible"
      :close-on-click-modal="false"	
      @close="cancleBtn('forms')"
      append-to-body>
        <el-form :model="forms" :rules="rules" ref="forms" label-width="100px" class="demo-ruleForm">
            <el-form-item label="字典标签" prop="labels">
                <el-input v-model="forms.labels" placeholder="请输入字典标签"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="state">
                  <el-radio-group v-model="forms.state">
                    <el-radio :label="0" border>正常</el-radio>
                    <el-radio :label="1" border>停用</el-radio>
                  </el-radio-group>
            </el-form-item>
            <el-form-item label="排序" prop="num">
                <el-input-number v-model="forms.num" controls-position="right" :min="0"></el-input-number>
            </el-form-item>
             <el-form-item label="备注" prop="remark">
                <el-input v-model="forms.remark" placeholder="请输入备注"></el-input>
            </el-form-item>
             <el-form-item label="value值" prop="values">
                <el-input-number v-model="forms.values" controls-position="right" :min="0"></el-input-number>
            </el-form-item>
            <el-form-item label="创建时间" v-if="innerFlags">
                <el-input v-model="forms.creatTime" disabled></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveDictDataDialog">保存</el-button>
            <el-button @click="cancleBtn('forms')">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { logsmanagerAPI } from 'liankong-ui-api'
export default {
    data(){
        return {
            innerVisible: false,
            innerRowInfo: {},
            innerFlags: false,
            forms: {
                labels: '',
                state: '',
                num: '',
                remark: '',
                values: '',
                creatTime: ''
            },
            rules: {
                labels: [
                    { required: true, message: '标签不能为空', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '备注不能为空', trigger: 'blur' }
                ],
                state: [
                    { required: true, message: '请选择状态', trigger: 'change' }
                ],
                num: [
                    { required: true, message: '请选择排序', trigger: 'change' }
                ],
                values: [
                    { required: true, message: 'value值不能为空', trigger: 'change' }
                ]
            },
        }
    },
    methods: {
        innerInit(innerFlags,innerRowInfo){
            this.innerFlags = innerFlags;
            this.innerRowInfo = innerRowInfo;

            if(innerFlags) {
                this.forms = {
                    labels: innerRowInfo.label,
                    state: innerRowInfo.state,
                    num: innerRowInfo.sort,
                    remark: innerRowInfo.remark,
                    values: innerRowInfo.value,
                    creatTime: this.transformTimestamp(innerRowInfo.createDate)
                }
            } else {
                this.forms = {
                    labels: '',
                    state: '',
                    num: '',
                    remark: '',
                    values: '',
                    creatTime: ''
                }
            }
        },
        //时间转换
        transformTimestamp(timestamp){
            let a = new Date(timestamp).getTime();
            const date = new Date(a);
            const Y = date.getFullYear() + '-';
            const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            const D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + '  ';
            const h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
            const m = (date.getMinutes() <10 ? '0'+date.getMinutes() : date.getMinutes()) + ':' ;
            const s = (date.getSeconds() <10 ? '0'+date.getSeconds() : date.getSeconds() ); // 秒
            const dateString = Y + M + D + h + m + s;
       
            return dateString;
        },
        // 保存编辑
        saveDictDataDialog(){
             this.$refs['forms'].validate((valid) => {
              if (valid) {

                if(this.innerFlags) {
                    let queryParams = {
                        id: this.innerRowInfo.id,
                        label: this.forms.labels,
                        state: this.forms.state,
                        sort: this.forms.num,
                        remark: this.forms.remark,
                        value: this.forms.values
                    }
                    logsmanagerAPI.dictListEdits(queryParams).then(res=>{
                      if(res.code == '0') {
                            this.$message.success('修改成功');
                            this.$emit('onRefreshList');
                            this.innerVisible = false;
                      } else {
                            this.$message.error(res.message);
                            this.innerVisible = false;
                      }
                    }).catch(error=>{
                        this.$message.error(error.message);
                    })
                } else {
                  let params = {
                    typeId: this.innerRowInfo.id,
                    label: this.forms.labels,
                    state: this.forms.state,
                    sort: this.forms.num,
                    remark: this.forms.remark,
                    value: this.forms.values
                  }
                  logsmanagerAPI.dictListAdds(params).then(res=>{
                      if(res.code == '0') {

                        this.$message.success('添加成功');
                        this.$emit('onRefreshList');
                        this.innerVisible = false;

                      } else {
                        this.$message.error(res.message);
                        this.innerVisible = false;
                      }
                  }).catch(error=>{
                      this.$message.error(error.message);
                  })
                }

              }
           })
        },
        // 取消
        cancleBtn(formName){
            this.$refs[formName].resetFields();
            this.innerVisible = false;
        }
    }
}
</script>