<template>
    <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline dictbox">
            <el-form-item label="字典名称">
                <el-input v-model="formInline.name" placeholder="字典名称"></el-input>
            </el-form-item>
            <el-form-item label="字典类型">
                <el-input v-model="formInline.code" placeholder="字典类型"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="onSubmit">搜索</el-button>
                <el-button icon="el-icon-delete" @click="clearList">清空</el-button>
            </el-form-item>
        </el-form>

        <el-button type="primary" icon="el-icon-plus" style="margin: 0 20px 20px 20px;" @click="handleAddDict(false)" v-if="newObj.sys_dict_add">新增</el-button>
        <!-- <el-button type="primary" icon="el-icon-refresh" style="margin: 0 20px 20px 0">刷新缓存</el-button> -->

       <div class="dict-table">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column
                type="index"
                align="center"
                label="序号"
                width="100">
                </el-table-column>
                <el-table-column
                prop="name"
                label="字典名称"
                align="center"
                width="200">
                </el-table-column>
                <el-table-column
                label="字典类型"
                align="center"
                width="180">
                    <template slot-scope="scope">
                        <span style="color: #409EFF;">{{scope.row.code}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                label="状态"
                align="center"
                width="150">
                    <template slot-scope="scope">
                        <el-tag type="primary" plain  v-if="scope.row.state == 0">正常</el-tag>
                        <el-tag type="info" plain v-if="scope.row.state == 1">停用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                label="创建时间"
                align="center"
                width="300">
                    <template slot-scope="scope">
                        {{scope.row.createDate | date}}
                    </template>
                </el-table-column>
                <el-table-column
                label="操作"
                align="center">
                    <template slot-scope="scope">
                        <el-button  type="text" size="small" icon="el-icon-menu" @click="searchDictDataList(scope.row)" v-if="newObj.sys_dict_datas">字典数据</el-button>
                        <el-button  type="text" size="small" icon="el-icon-edit" @click="handleAddDict(true, scope.row)" v-if="newObj.sys_dict_edit">编辑</el-button>
                        <el-button type="text"  @click="handleClick(scope.row)" size="small" icon="el-icon-delete" v-if="newObj.sys_dict_del">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
       </div>

       <el-pagination
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="formInline.pageNum"
        :page-size="formInline.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <dict-item ref="dictitem" @onRefreshDictList="getList"></dict-item>
        <dict-list ref="dictdataitem"></dict-list>

    </div>
</template>
<script>
import DictItem from './DictItem.vue'
import DictList from './DictList.vue'
import { logsmanagerAPI } from 'liankong-ui-api'

  export default {
    data() {
        return {
            total: 0,
            formInline: {
                name: '',
                code: '',
                pageNum: 1,
                pageSize: 10
            },
            tableData: [],
            newObj: {}
        }
    },
    components:{
        DictItem,
        DictList
    },
    computed: {
        roles(){
            return this.$store.state.login.roles || []
        }
    },
    created(){
        this.getList();

        this.roles.forEach(roItem=>{
            this.newObj[roItem.authority] = roItem.authority
        })
    },
    methods: {
        // 搜索
        onSubmit() {
            this.getList();
        },
        getList(){
            logsmanagerAPI.dictTypes(this.formInline).then(res=>{
                if(res.code == '0') {
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 添加、编辑
        handleAddDict(flag, row){
            this.$refs.dictitem.dialogVisible = true;

            this.$nextTick(()=>{
                this.$refs.dictitem.init(flag, row)
            })
        },
        // 字典数据
        searchDictDataList(row){
            this.$refs.dictdataitem.dialogTableVisible = true;
            this.$nextTick(()=>{
                this.$refs.dictdataitem.init(row);
            })
        },
        // 删除
        handleClick(row) {
            
            this.$confirm('是否确认删除名称为"' + row.name + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                logsmanagerAPI.deleteDictType(row.id).then(res=>{
                    if(res.code =='0') {
                        this.$message.success('删除成功');
                        this.getList();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(error=>{
                    this.$message.error(error.message);
                })
            })
        },
        // 分页
        handleCurrentChange(val){

            this.formInline.pageNum = val;
            this.getList();
        },
        // 清空
        clearList(){
            this.formInline = {
                name: '',
                code: '',
                pageNum: 1,
                pageSize: 10
            }
            this.getList();
        }
    }
  }
</script>
<style lang="less" scoped>
.dictbox {
    padding: 20px;
}
.dict-table {
    padding: 0 20px 20px 20px;
}
.pagebox {
    padding: 0px 20px 20px 20px;
}
</style>